/**
 * Types
 */
export const Types = {
  VINCULAR_DADOS: "VINCULAR_DADOS",
  CLEAR_VINCULAR_DADOS: "CLEAR_VINCULAR_DADOS",

  MODAL_VINCULAR: "MODAL_VINCULAR",

  MODAL_VINCULO_VENDEDOR: "MODAL_VINCULO_VENDEDOR",
  MODAL_VINCULO_GERENTE: "MODAL_VINCULO_GERENTE",
  MODAL_VINCULO_FINALIZAR: "MODAL_VINCULO_FINALIZAR",
  MODAL_VINCULO_VISUALIZAR: "MODAL_VINCULO_VISUALIZAR",
  MODAL_APROVAR_VINCULO: "MODAL_APROVAR_VINCULO",
  CLOSE_MODAL_CONFIRMA_VINCULO: "CLOSE_MODAL_CONFIRMA_VINCULO",

  MODAL_CONFIRMA_VINCULO: "MODAL_CONFIRMA_VINCULO",
  SET_ID_MODAL: "SET_ID_MODAL",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  vincular_dados: {},
  modal_confirma_vinculo: false,

  modal_vinculo_vendedor: false,
  modal_vincular_gerente: false,
  modal_vinculo_finaliza: false,
  modal_vinculo_visualizar: false,
  modal_aprovar_vinculo: false,
};

export default function ModalVinculo(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.VINCULAR_DADOS:
      return {
        ...state,
        vincular_dados: { ...state.vincular_dados, ...action.payload },
      };

    case Types.CLEAR_VINCULAR_DADOS:
      return {
        ...state,
        vincular_dados: {},
      };

    case Types.MODAL_VINCULO_VENDEDOR:
      return {
        ...state,
        modal_vinculo_vendedor: !state.modal_vinculo_vendedor,
      };

    case Types.SET_ID_MODAL:
      return {
        ...state,
        vincular_dados: {
          ...state.vincular_dados,
          ...action.payload,
        },
      };

    case Types.MODAL_VINCULO_GERENTE:
      return {
        ...state,
        modal_vincular_gerente: !state.modal_vincular_gerente,
      };

    case Types.MODAL_VINCULO_FINALIZAR:
      return {
        ...state,
        modal_vinculo_finaliza: !state.modal_vinculo_finaliza,
      };

    case Types.MODAL_VINCULO_VISUALIZAR:
      return {
        ...state,
        modal_vinculo_visualizar: !state.modal_vinculo_visualizar,
      };

    case Types.MODAL_APROVAR_VINCULO:
      return {
        ...state,
        modal_aprovar_vinculo: !state.modal_aprovar_vinculo,
      };

    case Types.MODAL_VINCULAR:
      return {
        ...state,
        modal_vincular: !state.modal_vincular,
      };

    case Types.MODAL_CONFIRMA_VINCULO:
      return {
        ...state,
        modal_confirma_vinculo: !state.modal_confirma_vinculo,
      };
    case Types.CLOSE_MODAL_CONFIRMA_VINCULO:
      return {
        ...state,
        modal_confirma_vinculo: false,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  vincular_dados: (tipo, dados) => {
    return {
      type: Types.VINCULAR_DADOS,
      payload: {
        [tipo]: dados,
      },
    };
  },

  clear_dados: () => {
    return {
      type: Types.CLEAR_VINCULAR_DADOS,
    };
  },

  modal_vinculo_vendedor: () => {
    return {
      type: Types.MODAL_VINCULO_VENDEDOR,
    };
  },

  set_id_modal: (dados) => {
    return {
      type: Types.SET_ID_MODAL,
      payload: {
        dados,
      },
    };
  },

  modal_vinculo_gerente: () => {
    return {
      type: Types.MODAL_VINCULO_GERENTE,
    };
  },

  modal_vinculo_finaliza: () => {
    return {
      type: Types.MODAL_VINCULO_FINALIZAR,
    };
  },

  modal_vincular: () => {
    return {
      type: Types.MODAL_VINCULAR,
    };
  },

  modal_confirma_vinculo: () => {
    return {
      type: Types.MODAL_CONFIRMA_VINCULO,
    };
  },
  clode_modal_confirma_vinculo: () => {
    return {
      type: Types.CLOSE_MODAL_CONFIRMA_VINCULO,
    };
  },

  modal_confirma_visualizar: () => {
    return {
      type: Types.MODAL_VINCULO_VISUALIZAR,
    };
  },
  modal_aprovar_vinculo: () => {
    return {
      type: Types.MODAL_APROVAR_VINCULO,
    };
  },
};
