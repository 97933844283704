import React, { useMemo } from "react";
import Table from "../tabelas";
import { formataDadosTabelaFinanciamento } from "../../utils/helper";

function Financiamento(props) {
  const { dados, filter } = props;

  const dadosTabela = useMemo(
    () => formataDadosTabelaFinanciamento(dados),
    [dados]
  );

  const columns = useMemo(
    () => [
      {
        Header: "CodRevend",
        accessor: "cod_revend",
      },
      {
        Header: "datCntrt",
        accessor: "dat_cntl",
      },
      {
        Header: "Gerente Plataforma",
        accessor: "gerente_plataforma",
      },
      {
        Header: "Grupo Economico",
        accessor: "grupo_economico",
      },
      {
        Header: "CNPJ Cliente",
        accessor: "cnpj",
      },
      {
        Header: "Nome Cliente",
        accessor: "nome_cliente",
      },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Modelo",
        accessor: "modelo",
      },
      {
        Header: "numCntrt",
        accessor: "num_cntrt",
      },
      {
        Header: "numpront",
        accessor: "numpront",
      },
      {
        Header: "veicChassi",
        accessor: "veic_chassi",
      },
      {
        Header: "nome_fornecedor",
        accessor: "nome_fornecedor",
      },
      {
        Header: "qtdbem",
        accessor: "qtd_bem",
      },
    ],
    []
  );

  function filtrarDados(arr, filtro) {
    if (arr.length && filtro !== "") {
      arr = arr.filter((user) =>
        JSON.stringify(user, [
          "acao_id",
          "cnpj",
          "cod_revend",
          "dat_cntl",
          "gerente_plataforma",
          "grupo_economico",
          "nome_cliente",
          "marca",
          "modelo",
          "num_cntrt",
          "numpront",
          "veic_chassi",
          "nome_fornecedor",
          "qtd_bem",
        ])
          .toLocaleLowerCase()
          .includes(filtro.toLocaleLowerCase())
      );

      return arr;
    }

    return arr;
  }

  const arrFilter = filtrarDados(
    dadosTabela.result ? dadosTabela.result : [],
    filter
  );

  return <Table columns={columns} data={arrFilter} item={dados} />;
}

export default Financiamento;
