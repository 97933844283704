import React from "react";

import { Container } from "./styles";

function Toggle(props) {
  const {
    active,
    change = () => {},
    title = "Aprovar",
    disabled = false,
  } = props;

  function handleClick() {
    change(!active);
  }

  return (
    <>
      <Container>
        <button
          onClick={() => handleClick()}
          className={`${active === true ? "active" : ""} toggle`}
          disabled={disabled}
        >
          <span></span>
        </button>

        <p>{title}</p>
      </Container>
    </>
  );
}

export default Toggle;
