/**
 * Types
 */
export const Types = {
  REQUETS_APROVAR_CONTRATO: "REQUETS_APROVAR_CONTRATO",
  REQUETS_REMOVER_APROVACAO_CONTRATO: "REQUETS_REMOVER_APROVACAO_CONTRATO",
  REQUETS_APROVAR_CONTRATO_MIT: "REQUETS_APROVAR_CONTRATO_MIT",
  REQUETS_CONTRATO_ADM: "REQUETS_CONTRATO_ADM",

  SUCCESS_APROVAR_CONTRATO: "SUCCESS_APROVAR_CONTRATO",
  FAILUIRE_APROVAR_CONTRATO: "FAILUIRE_APROVAR_CONTRATO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  loading: false,
  error: false,
  success: false,
};

export default function Aprovar(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUETS_APROVAR_CONTRATO:
      return { ...state, loading: true, error: false, success: false };
    case Types.REQUETS_REMOVER_APROVACAO_CONTRATO:
      return { ...state, loading: true, error: false, success: false };

    case Types.REQUETS_APROVAR_CONTRATO_MIT:
      return { ...state, loading: true, error: false, success: false };

    case Types.REQUETS_CONTRATO_ADM:
      return { ...state, loading: true, error: false, success: false };

    case Types.SUCCESS_APROVAR_CONTRATO:
      return { ...state, loading: false, error: false, success: true };

    case Types.FAILUIRE_APROVAR_CONTRATO:
      return { ...state, loading: false, error: true, success: false };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  request_aprovar_contarto: (contratos, status, seguro) => {
    return {
      type: Types.REQUETS_APROVAR_CONTRATO,
      payload: { contratos, status, seguro },
    };
  },

  request_remover_aprovacao_contrato: (contratos, status, seguro) => {
    return {
      type: Types.REQUETS_REMOVER_APROVACAO_CONTRATO,
      payload: { contratos, status, seguro },
    };
  },
  request_aprovar_contarto_mit: (contratos, status) => {
    return {
      type: Types.REQUETS_APROVAR_CONTRATO_MIT,
      payload: { contratos, status },
    };
  },

  request_contrato_adm: (dados) => {
    return {
      type: Types.REQUETS_CONTRATO_ADM,
      payload: dados,
    };
  },
};
