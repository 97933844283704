import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acaoAdm";

import {
  FormInitialState,
  validationCriacaoAcao,
} from "../../utils/configForm";
import Select from "../../components/SelectCustom";
import Buttom from "../../components/button";
import InputCalendar from "../../components/inputCalendar";

import closeImg from "../../assets/img/sistema/close.svg";
import plusImg from "../../assets/img/sistema/plus.svg";

import { NovaAcao } from "./styles";
import { formataMoney } from "../../utils/helper";

function FormAcao() {
  const dispatch = useDispatch();
  const options = [
    { value: 1, label: "Seguro" },
    { value: 2, label: "Financiamento" },
    { value: 3, label: "cartao_mit" },
    { value: 4, label: "speed" },
    { value: 5, label: "financiamento_valor" },
    { value: 6, label: "financiamento_valor_fixo" },
  ];

  return (
    <NovaAcao>
      <div className="container-header">
        <p>
          <img src={plusImg} alt="" /> Nova ação
        </p>
        <button onClick={() => dispatch(AcaoCreators.open_modal_store_acao())}>
          <img src={closeImg} alt="" />
        </button>
      </div>

      <div className="container-acao">
        <Formik
          enableReinitialize
          initialValues={FormInitialState.criarAcao}
          validationSchema={validationCriacaoAcao}
          onSubmit={(value, actions) => {
            if (value?.financiamento_valor_fixo) {
              value = {
                ...value,
                financiamento_valor_max: parseFloat(
                  value?.financiamento_valor_fixo
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".")
                ),
              };
            } else {
              value = {
                ...value,
                financiamento_valor_max: parseFloat(
                  value.financiamento_valor_max
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".") || "0"
                ),
              };
            }

            delete value?.financiamento_valor_fixo;

            dispatch(AcaoCreators.store(value));
          }}
        >
          {(props) => {
            const { setFieldValue, values } = props;

            return (
              <Form>
                <Select
                  id={1}
                  options={options}
                  label="Tipo da Ação"
                  change={({ value, label }) => {
                    if (
                      label === "financiamento_valor_fixo" ||
                      label === "financiamento_valor"
                    ) {
                      setFieldValue("financiamento_valor_max", "");
                      setFieldValue("financiamento_valor_fixo", "");
                    }
                    setFieldValue("type", label.toLowerCase());
                  }}
                />
                <ErrorMessage component="span" name="type" />

                <div className="container-input">
                  <Field type="text" name="nome" placeholder="Nome da Ação" />
                  <ErrorMessage component="span" name="nome" />
                </div>

                <InputCalendar
                  label="Data inicio"
                  onChange={(change) => setFieldValue("start_period", change)}
                />

                <InputCalendar
                  label="Data Termino"
                  onChange={(change) => setFieldValue("end_period", change)}
                />

                {values.type === "financiamento_valor" && (
                  <div className="container-input">
                    <Field
                      type="text"
                      value={values.financiamento_valor_max}
                      onChange={(change) => {
                        const value = change.target.value;
                        setFieldValue(
                          "financiamento_valor_max",
                          formataMoney(value)
                        );
                      }}
                      name="financiamento_valor_max"
                      placeholder="Valor máximo"
                    />
                    <ErrorMessage
                      component="span"
                      name="financiamento_valor_max"
                    />
                  </div>
                )}

                {values.type === "financiamento_valor_fixo" && (
                  <div className="container-input">
                    <Field
                      type="text"
                      value={values.financiamento_valor_fixo}
                      onChange={(change) => {
                        const value = change.target.value;
                        setFieldValue(
                          "financiamento_valor_fixo",
                          formataMoney(value)
                        );
                      }}
                      name="financiamento_valor_fixo"
                      placeholder="Valor máximo"
                    />
                    <ErrorMessage
                      component="span"
                      name="financiamento_valor_fixo"
                    />
                  </div>
                )}

                <Buttom active={true} title="ENVIAR" type="submit" />
              </Form>
            );
          }}
        </Formik>
      </div>
    </NovaAcao>
  );
}

export default FormAcao;
