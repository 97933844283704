import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ObjIsEmpty } from "../../utils/helper";

import SelectCustom from "../../components/SelectCustom";
import contrato from "../../assets/img/sistema/contrato.svg";

import { Container } from "./styles";

const options = [
  { value: 1, label: "Ação 1" },
  { value: 2, label: "Ação 2" },
  { value: 3, label: "Ação 3" },
];

function FiltroContrato(props) {
  const { setItemSelect, select, placeholder } = props;

  const history = useHistory();

  const {
    location: { itemselect },
  } = history;

  const { dados } = useSelector((state) => state.gerentePlataforma);

  const [selectedItem, setSelectedItem] = useState();

  const { countApproved, countWainting, countSemVinculo } = useMemo(() => {
    if (!dados) return 0;

    const result = ObjIsEmpty(selectedItem ?? {})
      ? dados[itemselect?.label]
        ? dados[itemselect?.label]
        : []
      : dados[selectedItem.label]
      ? dados[selectedItem.label]
      : [];

    const values = [];
    Object.values(result).forEach((item) => {
      values.push(...item);
    });

    const countApproved = values
      .filter((item) => item.status.id === 1)
      .reduce((accumulator) => accumulator + 1, 0);

    const countWainting = values
      .filter((item) => item.status.id === 1 || item.status.id === 2)
      .reduce((accumulator) => accumulator + 1, 0);

    const countSemVinculo = values
      .filter((item) => item.status.id === 3)
      .reduce((accumulator) => accumulator + 1, 0);

    return { countApproved, countWainting, countSemVinculo };
  }, [dados, itemselect, selectedItem]);

  return (
    <Container>
      <div className="container-select">
        <SelectCustom
          label={placeholder}
          options={select.length ? select : options}
          change={(value) => {
            setSelectedItem(value);
            setItemSelect(value);
          }}
        />
      </div>

      <div className="detalhes">
        <div className="container-contratos">
          <img src={contrato} alt="" />
          <p>Contratos disponíveis - {countWainting + countSemVinculo}</p>
        </div>

        <div className="container-contratos">
          <img src={contrato} alt="" />
          <p>Contratos sem Vínculo - {countSemVinculo}</p>
        </div>

        <div className="container-contratos">
          <img src={contrato} alt="" />
          <p>Contratos vinculados - {countWainting}</p>
        </div>

        <div className="container-contratos">
          <img src={contrato} alt="" />
          <p>Contratos Aprovados - {countApproved}</p>
        </div>
      </div>
    </Container>
  );
}

export default FiltroContrato;
