/**
 * Types
 */
export const Types = {
  OPEN_MODAL_MODAL_ACTION: "OPEN_MODAL_MODAL_ACTION",
  CLOSE_MODAL_MODAL_ACTION: "CLOSE_MODAL_MODAL_ACTION",

  OPEN_MODAL_REMOVER_APROVACAO: "OPEN_MODAL_REMOVER_APROVACAO",
  CLOSE_MODAL_REMOVER_APROVACAO: "CLOSE_MODAL_REMOVER_APROVACAO",

  OPEN_MODAL_SEM_VINCULO: "OPEN_MODAL_SEM_VINCULO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  modal_action: false,
  modal_sem_vinculo: false,
  modal_remover_aprovacao: false,
  dados: {},
};

export default function ModalAction(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_MODAL_MODAL_ACTION:
      return {
        ...state,
        modal_action: true,
        dados: action.payload,
      };

    case Types.OPEN_MODAL_SEM_VINCULO:
      return {
        ...state,
        modal_sem_vinculo: true,
        dados: action.payload,
      };

    case Types.CLOSE_MODAL_MODAL_ACTION:
      return {
        ...state,
        modal_action: false,
        modal_sem_vinculo: false,
        dados: {},
      };

    /**
     * Modal Remover aprovacao
     */
    case Types.OPEN_MODAL_REMOVER_APROVACAO:
      return {
        ...state,
        modal_action: false,
        modal_sem_vinculo: false,
        modal_remover_aprovacao: true,
        dados: action.payload,
      };

    case Types.CLOSE_MODAL_REMOVER_APROVACAO:
      return {
        ...state,
        modal_remover_aprovacao: false,
        dados: {},
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  open: (dados) => {
    return { type: Types.OPEN_MODAL_MODAL_ACTION, payload: dados };
  },

  close: () => {
    return { type: Types.CLOSE_MODAL_MODAL_ACTION };
  },

  openModalRemoverAprovacao: (dados) => {
    return { type: Types.OPEN_MODAL_REMOVER_APROVACAO, payload: dados };
  },

  closeModalRemoverAprovacao: () => {
    return { type: Types.CLOSE_MODAL_REMOVER_APROVACAO };
  },

  alerta: () => {
    return { type: Types.OPEN_MODAL_SEM_VINCULO };
  },
};
