import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	*{
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		outline: 0px;
		font-family: 'Inter', sans-serif;
		font-weight: 400;
	}

	.fix-body{
		overflow: hidden;
  }

  .disabled{
      pointer-events: none;
      opacity: 0.5;
  }

  &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        transition: all 0.5s;
    }

  .container-form{
		margin-bottom: 14px;
    position: relative;
    width: 383px;
    .check-password {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        height: auto;
        width: auto;
        &.active {
            &:before {
                height: 30px;
                transition: all 0.5s;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 0px;
            background-color: #858585;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all 0.5s;
        }
        img {
            position: static;
            transform: unset;
            margin-bottom: 0px;
        }
    }
    &.select{
        background-color: #F7F7F7;
    }
    img {
      position: absolute;
      right: 10px;
      top: 20px;
      cursor: pointer;
      &.info {
        right: -30px;
      }
    }
  }

  input[type='text'],input[type='password'], input[type="number"], .custom-file{
    background: ${(props) => props.theme.colors.Cinza05};
    border: none;
    border-radius: 4px;
    height: 58px;
    width: 383px;
    padding-left: 29px;
    padding-right: 20px;
    font-family: "Lato-Regular";
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.Cinza03};
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &:focus {
      color: ${(props) => props.theme.colors.preto};
      background: transparent;
    }
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  .custom-file {
    border: 1px dashed #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type='file'] {
      display: none;
    }
    &.nome-arquivo {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.preto};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.scroll-y{
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(89,92,118,0.5);

		}

		&::-webkit-scrollbar-track {

			background-color: rgba(255,255,255, 0.1);
		}
	}

	#root{
		margin-left: auto;
		margin-right: auto;
		max-width: 1911px;
    min-height: 100vh;
	}

	a,button{
		text-decoration: none;
		outline: none;
		border: none;
		cursor: pointer;
	}

	.container{
		width: 1215px;
		margin-left: auto;
		margin-right: auto;
	}

  .preview {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin: 50px 0;
    .container-text {
      h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 18px;
      }
    }
  }

	li{
		list-style: none;
	}

	body{
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		/* font-family: Montserrat; */
	}

	@media (max-width: 1366px) {
		.container {
			width: 90%;
		}
  }

  @media (max-width: 480px) {
    .container-form{
      width: 100%;
      input{
        width: 100%;
      }
    }
  }

  .span-duvidas {
    margin-top: 20px!important;
    font-size: 18px!important;
  }
`;
