import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  background: #fff;
`;

export const Conteudo = styled.div`
  .conteudo {
    display: flex;
    flex-direction: column;
    padding: 30px;
    .container-toogles {
      .toogles {
        display: flex;
        justify-content: space-between;
        width: 275px;

        button {
          &.remover {
            background: transparent;
            font-size: 14px;
            font-weight: 600;
            color: rgb(147, 0, 53);
            &[disabled] {
              pointer-events: inherit;
              cursor: not-allowed;
            }
          }
        }
      }
    }
    .container-acordion {
      height: 60px;
      overflow: hidden;
      transition: all 0.5s;
      &.active {
        transition: all 0.5s;
      }
      .header-acordion {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          img {
            display: block;
            margin-left: 66px;
          }
        }
      }

      .container-table {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 31px 0px;
      }
    }
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 205px 1fr;
    grid-gap: 150px;
    padding-left: 150px;

    .s-esq {
      img {
        margin-bottom: 40px;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 120%;
        position: relative;
        color: ${(props) => props.theme.colors.Vinho1};
        font-weight: 900;
        &::before {
          content: "";
          width: 9px;
          height: 9px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;
          background-color: ${(props) => props.theme.colors.Laranja1};
        }
      }
    }
    .s-dir {
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
      button {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;
