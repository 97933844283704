import React from "react";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import "react-datepicker/dist/react-datepicker.css";

import { Container } from "./styles";
// import icone from "../../assets/img/admin/calendario.svg";

function InputCalendar({ onChange = () => {}, label = "" }) {
  const [date, setDate] = React.useState(null);

  function convertDatePickerTimeToUser(str) {
    const data = str.split(" ")[0];
    const tempo = str.split(" ")[1];

    return data.split("-").reverse().join("/") + " " + tempo;
  }

  function convertDatePickerTimeToMySQLTime(str) {
    var hours, minutes, seconds;
    var date = new Date(str);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);
    seconds = ("0" + date.getSeconds()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    var mySQLTime = [hours, minutes, seconds].join(":");
    return [mySQLDate, mySQLTime].join(" ");
  }

  return (
    <Container>
      <DatePicker
        locale={ptBR}
        placeholderText={
          date
            ? convertDatePickerTimeToUser(
                convertDatePickerTimeToMySQLTime(date)
              )
            : label
        }
        timeFormat="p"
        // timeCaption="time"
        timeIntervals={30}
        dateFormat="Pp"
        showTimeSelect
        selected={date}
        onChange={(date) => {
          onChange(convertDatePickerTimeToMySQLTime(date));
          setDate(date);
        }}
      />
    </Container>
  );
}

export default InputCalendar;
