import { put, call } from "redux-saga/effects";
import { Types as AprovarTypes } from "../ducks/aprovarContratos";
import { Types as GerenteTypes } from "../ducks/gerentePlataforma";
import { Creators as ModalActionCreaators } from "../ducks/modalActionContrato";
import { Creators as ModalAction } from "../ducks/modalAction.js";
import { Creators as ModalEditarContratoCreaators } from "../ducks/modalEditarContrato";
import { Creators as CargaAcaoAdmCreators } from "../../store/ducks/cargaAcaoAdm";

import { api } from "../../services/api";

export function* aprovarContrato({ payload }) {
  const { contratos, status, seguro } = payload;

  try {
    yield call(api.post, `${seguro ? "/produto/status" : "/contrato/status"}`, {
      contratos,
      status_id: status,
    });

    yield put({
      type: AprovarTypes.SUCCESS_APROVAR_CONTRATO,
    });

    yield put({
      type: GerenteTypes.REQUETS_GERENTE_PLATAFORMA,
    });

    yield put(ModalActionCreaators.close());
    yield put(ModalAction.close());
  } catch (error) {
    yield put({
      type: AprovarTypes.FAILUIRE_APROVAR_CONTRATO,
    });
    yield put(ModalActionCreaators.close());
    yield put(ModalAction.close());
  }
}

export function* removerAprovacaoContrato({ payload }) {
  const { contratos } = payload;

  try {
    yield call(api.post, `contrato/remove-bind`, {
      contratos,
    });

    yield put({
      type: AprovarTypes.SUCCESS_APROVAR_CONTRATO,
    });

    yield put({
      type: GerenteTypes.REQUETS_GERENTE_PLATAFORMA,
    });

    yield put(ModalActionCreaators.close());
    yield put(ModalAction.closeModalRemoverAprovacao());
  } catch (error) {
    yield put({
      type: AprovarTypes.FAILUIRE_APROVAR_CONTRATO,
    });
  }
}

export function* aprovarContratoAcaoMit({ payload }) {
  const { contratos, status } = payload;

  try {
    yield call(api.post, `/cartao/status`, {
      contratos,
      status_id: status,
    });

    yield put({
      type: AprovarTypes.SUCCESS_APROVAR_CONTRATO,
    });

    yield put({
      type: GerenteTypes.REQUETS_GERENTE_PLATAFORMA,
    });

    yield put(ModalActionCreaators.close());
  } catch (error) {
    yield put({
      type: AprovarTypes.FAILUIRE_APROVAR_CONTRATO,
    });
  }
}

export function* contratoAdm({ payload }) {
  console.log(payload);

  const { contrato, acao_type, id: contrato_id, user } = payload;
  const { id } = contrato;

  try {
    if (acao_type === "financiamento") {
      yield call(api.post, `contrato/${id}`, {
        user_gtp_id: contrato.gerente_plataforma.id,
      });
    }

    if (acao_type === "seguro") {
      yield call(api.post, `produto/${id}`, {
        user_gtp_id: user.id,
      });
    }

    if (acao_type === "cartao_mit") {
      yield call(api.post, `cartao/${id}`, {
        user_gtp_id: contrato.id,
      });
    }

    yield put(ModalEditarContratoCreaators.close());
    yield put(ModalActionCreaators.close());
    yield put(CargaAcaoAdmCreators.request(contrato_id));
  } catch (error) {
    console.log(error);
    yield put(ModalActionCreaators.close());
    yield put(ModalEditarContratoCreaators.close());
  }
}
