import React, { useState, useEffect, useMemo } from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as VinculoCreators } from "../../store/ducks/vinculo";
import { Creators as FuncionariosCreators } from "../../store/ducks/funcionario";

import {
  ObjIsEmpty,
  formatCurrentMoney,
  formataMoney,
} from "../../utils/helper";
import { ToggleFilterStyles } from "./styles";

import Lista from "./lista";
import Funcionarios from "../../components/modalVinculoSeguro/adicionaFuncionario";
import Button from "../../components/button";
import circulo from "../../assets/img/sistema/circle-arrows.svg";
import plusVinculo from "../../assets/img/sistema/plus-vinculo-seguro.svg";

function ToggleFilter(props) {
  const { itens, placeholder, title, tipo } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [valor_financiamento, setValorFinanciamento] = useState("");
  const [selectItem, setSelectItem] = useState({});
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [showItens, setShowItens] = useState(false);
  const [arrFilter, setArrFilter] = useState([]);

  const { vincular_dados } = useSelector((state) => {
    return state.modalVinculo;
  });
  const valorDefault =
    vincular_dados?.dados?.acao?.financiamento_valor_max || 9999999;
  const { dados } = vincular_dados;
  const tipoAcao = dados?.acao?.tipo_atual;
  const temValorMaximo = tipoAcao === "financiamento_valor_fixo";
  const excludedItens = useMemo(() => {
    const result = [];

    if (vincular_dados.vendedor) {
      result.push(vincular_dados.vendedor);
    }

    if (vincular_dados.gerente) {
      result.push(vincular_dados.gerente);
    }

    return result;
  }, [vincular_dados]);

  function filter(arr, value) {
    if (!value) return arr;

    return arr.filter((item) => JSON.stringify(item, ["cpf"]).includes(value));
  }

  function vincular() {
    setArrFilter(itens);
    const valor = parseFloat(
      valor_financiamento
        ? valor_financiamento.replace(/[^0-9!,]/g, "").replace(",", ".")
        : "0"
    );

    setValorFinanciamento("");

    if (tipo === "vendedor") {
      dispatch(
        ModalCreators.vincular_dados(tipo, {
          ...selectItem,
          valor: valor ? valor : 0.0,
        })
      );
      dispatch(ModalCreators.modal_vinculo_vendedor());
      dispatch(ModalCreators.modal_confirma_vinculo());
    } else {
      dispatch(
        ModalCreators.vincular_dados(tipo, {
          ...selectItem,
          valor: valor ? valor : 0.0,
        })
      );
      dispatch(ModalCreators.modal_vinculo_gerente());
      dispatch(ModalCreators.modal_confirma_vinculo());
    }
  }

  function pular() {
    dispatch(ModalCreators.vincular_dados(tipo, { valor: 0.0 }));
    if (tipo === "vendedor") {
      dispatch(ModalCreators.modal_vinculo_vendedor());
      dispatch(ModalCreators.modal_vinculo_gerente());
    } else {
      dispatch(ModalCreators.modal_vinculo_gerente());
      dispatch(VinculoCreators.requestVinculo(vincular_dados));
      dispatch(ModalCreators.clear_dados());
    }
  }

  function handleShowFuncionario() {
    setShowAddFuncionario(true);
    setShowItens(false);
  }

  useEffect(() => {
    if (!ObjIsEmpty(selectItem)) {
      setValue("");
      setArrFilter(itens);
    } else {
      setShowItens(false);
    }
  }, [selectItem, itens]);

  useEffect(() => {
    const acao_id = vincular_dados?.dados?.acao_id;

    if (acao_id) {
      dispatch(FuncionariosCreators.request(acao_id));
    }
  }, [dispatch, vincular_dados?.dados?.acao_id]);

  useEffect(() => {
    if (temValorMaximo) {
      setValorFinanciamento(dados.vendedor_valor);
      return;
    }

    const tempValue = parseFloat(
      valor_financiamento
        ? valor_financiamento.replace(/[^0-9!,]/g, "").replace(",", ".")
        : "0"
    );

    const somatoria = vincular_dados?.vendedor?.valor + tempValue || 0;

    if (tempValue > valorDefault) {
      console.log(tempValue);
      alert(
        `Valor máximo permitido é de R$${formatCurrentMoney(valorDefault)}`
      );
      setValorFinanciamento("");
    }

    if (somatoria > valorDefault) {
      alert(
        `Valor máximo permitido é de R$${formatCurrentMoney(valorDefault)}`
      );
      setValorFinanciamento("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valor_financiamento,
    vincular_dados?.vendedor?.valor,
    dados?.acao?.financiamento_valor_max,
  ]);

  return (
    <ToggleFilterStyles>
      <div className="container-toggle seguro">
        <div
          className={`${
            !showItens && showAddFuncionario === false
              ? "container-list active"
              : "container-list"
          }`}
        >
          <h3>
            {title}{" "}
            <button onClick={handleShowFuncionario}>
              <img src={plusVinculo} alt="" />
            </button>
          </h3>
          <div className="container-input">
            <CpfCnpj
              type="text"
              placeholder={placeholder}
              name="cpf"
              value={value}
              maskChar={null}
              onChange={(e) => {
                setValue(e.target.value);
                setArrFilter(filter(itens, e.target.value));
              }}
            />
          </div>

          <Lista
            setItem={setSelectItem}
            setShowItens={setShowItens}
            itens={value ? arrFilter : itens}
            excludedItens={excludedItens}
          />
        </div>

        <div
          className={`${
            showItens ? "container-result active" : "container-result "
          }`}
        >
          <div className="containerCard-vinculos">
            <div className="card-vinculo">
              <span>{selectItem?.nome}</span>
              <span>{selectItem?.cpf}</span>
              <button onClick={() => setShowItens(false)}>
                <img src={circulo} alt="" />
              </button>
            </div>

            {dados?.acao?.hasfinanciamento2 && (
              <input
                className="default"
                type="tel"
                placeholder="VALOR"
                value={valor_financiamento}
                disabled={temValorMaximo}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length) {
                    setValorFinanciamento(formataMoney(value));
                  }
                }}
              />
            )}
          </div>

          <div className="container-btns">
            <Button
              title="Vincular"
              onClick={() => {
                setShowItens(false);
                vincular();
              }}
            />
            {tipoAcao !== "financiamento_valor_fixo" ? (
              <Button
                active={true}
                title="Pular"
                onClick={() => {
                  pular();
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className={`${
            showAddFuncionario
              ? "container-funcionarios active"
              : "container-funcionarios"
          } `}
        >
          <Funcionarios
            acao_id={vincular_dados ? vincular_dados?.dados?.acao_id : null}
            setShowAddFuncionario={setShowAddFuncionario}
          />
        </div>
      </div>
      {!value && !showItens && showAddFuncionario === false ? (
        <>
          {!temValorMaximo ? (
            <Button
              active={true}
              title="Pular"
              onClick={() => {
                pular();
              }}
            />
          ) : (
            <> </>
          )}
        </>
      ) : null}
    </ToggleFilterStyles>
  );
}

export default ToggleFilter;
