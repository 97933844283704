import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import {
  validadtionFuncionarios,
  FormInitialState,
} from "../../utils/configForm";
import { validaCPF, cnpjValidation } from "../../utils/helper";
import Button from "../../components/button";
import { Creators as FuncionariosCreators } from "../../store/ducks/funcionario";

// import { Container } from './styles';

function AdicionaFuncionario({ acao_id, setShowAddFuncionario }) {
  const dispatch = useDispatch();
  const { success_create } = useSelector((state) => state.funcionario);

  useEffect(() => {
    if (success_create) {
      setShowAddFuncionario(false);
    }
  }, [success_create, setShowAddFuncionario]);

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialState.funcionario}
      validationSchema={validadtionFuncionarios}
      onSubmit={(value, action) => {
        const { documento, nome } = value;
        var str = documento.replace(/[.\-/]/g, "");
        const verificaEspaco = nome.indexOf(" ") >= 0;

        if (validaCPF(str) || cnpjValidation(parseInt(str))) {
          if (verificaEspaco) {
            action.resetForm();
            dispatch(
              FuncionariosCreators.create({
                documento: str,
                nome,
                acao_id,
              })
            );
          } else {
            action.setFieldError("nome", "Preencha o nome completo");
          }
        } else {
          action.setFieldError(
            "documento",
            "O número de CPF ou CPNJ não é válido"
          );
        }
      }}
    >
      {(props) => {
        const { values, setFieldValue } = props;
        const { documento } = values;

        return (
          <Form>
            <h2>Adicionar Funcionario</h2>

            <div className="container-input">
              <Field type="text" name="nome" placeholder="Nome Completo" />
              <ErrorMessage component="span" name="nome" />
            </div>
            <div className="container-input">
              <CpfCnpj
                type="text"
                placeholder="CPF ou CNPJ"
                name="documento"
                onChange={(event, type) => {
                  setFieldValue("documento", event.target.value);
                }}
                value={documento}
              />
              <ErrorMessage component="span" name="documento" />
            </div>

            <div className="btns-add-funcionario">
              <Button title="Adicionar" type="submit" />
              <Button
                title="Cancelar"
                type="button"
                onClick={() => setShowAddFuncionario(false)}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AdicionaFuncionario;
