import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DebounceInput } from "react-debounce-input";

import {
  dowloadFile,
  formataMoney,
  formatCurrentMoney,
  tipoFinanciamento,
} from "../../utils/helper";
import { api } from "../../services/api";

import Button from "../../components/button";
import Toggle from "../../components/Toggle";
import SelectCustom from "../../components/SelectCustom";

import Modal from "../modal";
import ModalAction from "../ModalActions";

import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";
import { Creators as CargaAcaoAdmCreatos } from "../../store/ducks/cargaAcaoAdm";
import { Creators as CargaAcaoAdmCreators } from "../../store/ducks/cargaAcaoAdm";

import { Container } from "./styles";
import { toast } from "react-toastify";

function FiltroContrato(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [config, setConfig] = useState();
  const [loadingCarga, setLoadingCarga] = useState(false);
  const [loadingPontos, setLoadingPontos] = useState(false);
  const [loadingContratos, setLoadingContratos] = useState(false);
  const [ptsCartaoMit, setPtsCartaoMit] = useState(false);
  const [exportFuncionarios, setExportFuncionarios] = useState(false);
  const [exportFuncionariosYetz, setExportFuncionariosYetz] = useState(false);
  const [contratosCartaoMit, setContratosCartaoMit] = useState(false);
  const [deleteCarga, setDeleteCarga] = useState(false);
  const [valueFinanciamentoValor, setValueFinanciamentoValor] = useState("");
  const { location } = history;
  const itemselect = location?.itemselect;
  const { setItemSelect, select = [], placeholder } = props;

  const { data } = useSelector((state) => state.cargaAcaoAdm);
  const { modal_action } = useSelector((state) => state.modalAction);
  const { acao_id } = useSelector((state) => state.acaoAdm);

  const { approve_enable, bind_enable, status, acao_type, admList } = data;

  const acao = {
    title: "Ação",
    description: "Deseja realmente alterar o status destá ação?",
    onClick: changeStatusAcao,
    closeModal: closeModal,
  };

  const exclusão = {
    title: "Ação",
    description: "Deseja realmente excluir esta ação?",
    onClick: deleteCargas,
    closeModal: closeModal,
  };

  const vinculo = {
    title: "Vinculo",
    description: "Deseja realmente alterar o status deste vínculo(s)?",
    onClick: changeVinculoContratos,
    closeModal: closeModal,
  };

  const aprovar = {
    title: "Vinculo",
    description: "Deseja realmente alterar o status deste vínculo(s)?",
    onClick: changeAprovarVinculo,
    closeModal: closeModal,
  };

  function closeModal() {
    dispatch(ModalActionCreators.close());
  }

  /**
   * Active
   */
  function handleActiveAction(active) {
    const config = {
      status: active,
    };
    setConfig(acao);
    dispatch(ModalActionCreators.open(config));
  }

  function changeStatusAcao() {
    dispatch(CargaAcaoAdmCreatos.change_status_acao());
  }

  /**
   * Vincular
   */
  function handleVincularContratos(active) {
    const config = {
      status: active,
    };
    setConfig(vinculo);
    dispatch(ModalActionCreators.open(config));
  }

  /**
   * delete
   */
  function handleDelete(active) {
    const config = {
      status: active,
    };
    setConfig(exclusão);
    dispatch(ModalActionCreators.open(config));
  }

  function changeVinculoContratos() {
    dispatch(CargaAcaoAdmCreatos.change_status_vinculo());
  }

  /**
   * Aprovar Vinculo
   */
  function handleAprovarVinculo(active) {
    const config = {
      status: active,
    };
    setConfig(aprovar);
    dispatch(ModalActionCreators.open(config));
  }

  function changeAprovarVinculo() {
    dispatch(CargaAcaoAdmCreatos.change_status_aprovar_vinculo());
  }

  async function exportarCarga() {
    setLoadingCarga(true);

    await dowloadFile(`/acao/${acao_id}/export/products`);

    setLoadingCarga(false);
  }

  async function exportarPontos() {
    setLoadingPontos(true);

    await dowloadFile(`acao/${acao_id}/export`);

    setLoadingPontos(false);
  }

  async function exportarContratos() {
    setLoadingContratos(true);

    await dowloadFile(`acao/${acao_id}/export/contracts`);

    setLoadingContratos(false);
  }

  async function exportarContratosSeguro() {
    setLoadingContratos(true);

    await dowloadFile(`acao/${acao_id}/export/products`);

    setLoadingContratos(false);
  }

  async function exportarPontosCartao() {
    setPtsCartaoMit(true);

    await dowloadFile(`acao/${acao_id}/export/cartao-pontos`);

    setPtsCartaoMit(false);
  }

  async function exportarContratosCartao() {
    setContratosCartaoMit(true);

    await dowloadFile(`acao/${acao_id}/export/cartao`);

    setContratosCartaoMit(false);
  }

  async function exportarFuncionarios() {
    setExportFuncionarios(true);

    await dowloadFile(`/acao/${acao_id}/export/funcionarios`);

    setExportFuncionarios(false);
  }

  async function exportarFuncionariosYetz() {
    setExportFuncionariosYetz(true);

    await dowloadFile(`acao/${acao_id}/export/funcionarios-yetz`);

    setExportFuncionariosYetz(false);
  }

  async function deleteCargas() {
    setDeleteCarga(true);

    try {
      await api.delete(`carga/${acao_id}`);
      // toast.success("Ação excluída com sucesso!");
    } catch (error) {
      // toast.error("Erro ao deletar ação");
      console.log(error);
    }

    dispatch(CargaAcaoAdmCreators.request(acao_id));
    dispatch(ModalActionCreators.close());
    setDeleteCarga(false);
  }

  async function updateFinanciamentoValor(valor) {
    try {
      await api.post(`acao/${acao_id}`, {
        financiamento_valor_max: valor,
      });
      toast.success("Valor atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar valor");
      console.log(error);
    }
  }

  useEffect(() => {
    if (itemselect?.financiamento_valor_max) {
      setValueFinanciamentoValor(
        `R$${formatCurrentMoney(itemselect?.financiamento_valor_max)}`
      );
    }
  }, [itemselect?.financiamento_valor_max]);

  useEffect(() => {
    const id = admList[0]?.acao_id;
    if (id) {
      const item = select.find((item) => item.value === id);
      if (item)
        setValueFinanciamentoValor(
          `R$${formatCurrentMoney(item.financiamento_valor_max || "0")}`
        );
    }
  }, [admList, select]);

  return (
    <Container>
      <div className="container-select">
        <SelectCustom
          label={placeholder}
          options={select.length ? select : []}
          change={(value) => {
            setItemSelect(value);
          }}
        />
      </div>
      <div className="detalhes">
        <div className="container-toggles">
          <Toggle
            title="Ativar Ação"
            active={status ? true : false}
            change={() => handleActiveAction(status ? true : false)}
          />
          <Toggle
            title="Vincular Contratos"
            active={bind_enable ? true : false}
            change={() => handleVincularContratos(bind_enable ? true : false)}
          />
          <Toggle
            title="Aprovar Vínculos"
            active={approve_enable ? true : false}
            change={() => handleAprovarVinculo(approve_enable ? true : false)}
          />
        </div>

        <div className="container-input">
          {tipoFinanciamento.includes(acao_type) ? (
            <DebounceInput
              type="text"
              debounceTimeout={1000}
              value={valueFinanciamentoValor}
              placeholder="R$ 0,00"
              onChange={async (change) => {
                const value = formataMoney(change.target.value);
                const formated = parseFloat(
                  value.replace(/[^0-9!,]/g, "").replace(",", ".") || "0"
                );
                setValueFinanciamentoValor(value);
                await updateFinanciamentoValor(formated);
              }}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="container-btns">
          {acao_type === "cartao_mit" ? (
            <>
              <Button
                active={true}
                title="Exportar Pontos"
                onClick={() => exportarPontosCartao()}
                loading={ptsCartaoMit}
              />
              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratosCartao()}
                loading={contratosCartaoMit}
              />
            </>
          ) : null}
          {acao_type === "financiamento" ||
          tipoFinanciamento.includes(acao_type) ? (
            <>
              <Button
                active={true}
                title="Exportar Pontos"
                onClick={() => exportarPontos()}
                loading={loadingPontos}
              />
              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratos()}
                loading={loadingContratos}
              />
            </>
          ) : null}

          {acao_type === "seguro" ? (
            <>
              <Button
                active={true}
                title="Exportar Carga"
                onClick={() => exportarCarga()}
                loading={loadingCarga}
              />

              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratosSeguro()}
                loading={loadingContratos}
              />
            </>
          ) : null}

          <Button
            className="auto-fit"
            active={true}
            title="Exportar Funcionarios"
            onClick={() => exportarFuncionarios()}
            loading={exportFuncionarios}
          />
          <Button
            className="auto-fit"
            active={true}
            title="Funcionarios (Padrão Yetz) "
            onClick={() => exportarFuncionariosYetz()}
            loading={exportFuncionariosYetz}
          />
          <Button
            className="auto-fit"
            active={true}
            title="Exclusão de cargas"
            onClick={() => handleDelete(false)}
            loading={deleteCarga}
          />
        </div>
      </div>

      <Modal component={ModalAction} active={modal_action} rest={config} />

      <Modal component={ModalAction} active={modal_action} rest={config} />
    </Container>
  );
}

export default FiltroContrato;
