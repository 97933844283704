import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";

import link from "../../assets/img/sistema/Interface.svg";

import { Container } from "./styles";
import Button from "../../components/button";

function Finalizar() {
    const dispatch = useDispatch();
    function close() {
        dispatch(ModalCreators.modal_vinculo_finaliza());
    }

    return (
        <Container className="finalizar">
            <div className="content">
                <img src={link} alt="" />

                <h3>Vínculo(s) realizado(s) com sucesso!</h3>

                <div className="container-btns">
                    <Button title="Finalizar" onClick={() => close()} />
                </div>
            </div>
        </Container>
    );
}

export default Finalizar;
