import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Creators as LoginCreators } from "../../store/ducks/login";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";

import Acoes from "../../components/boxAcoes";
import NovaAcao from "../../components/boxAcoes/novaAcao";
import Footer from "../../components/Footer";

import Modal from "../../components/modal";
import ModalGenerico from "../../components/modalAdm/modalgenerico";
import FormAcao from "./formAcao";
import ModalLogout from "./modalLogout";

import { Container } from "./styles";

import logo from "../../assets/img/sistema/logo.svg";

function Sistema() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [config, setConfig] = useState();
  const [modalLogout, setModalLogout] = useState(false);

  const { name, nivel } = useSelector((state) => state.user);
  const { modal_success_or_failure, modal_acao_store } = useSelector(
    (state) => state.acaoAdm
  );
  const handleModalGenerico =
    modal_success_or_failure.erro || modal_success_or_failure.success;

  const confirUsuarioSemAcao = {
    onClick: () => dispatch(LoginCreators.requestLogout(history)),
  };

  const closeModal = useCallback(() => {
    dispatch(
      AcaoAdmCreators.modal_success_or_failure({
        error: false,
        success: false,
      })
    );
    setTimeout(() => {
      window.location.reload();
    }, 750);
  }, [dispatch]);

  useEffect(() => {
    if (modal_success_or_failure.erro) {
      setConfig({
        title: "Erro",
        description: "Algo de errado aconteceu tente novamente",
        closeModal: closeModal,
      });
    }

    if (modal_success_or_failure.success) {
      setConfig({
        title: "Sucesso",
        description: "Nova Ação adicionada",
        closeModal: closeModal,
      });
    }
  }, [modal_success_or_failure, closeModal]);

  return (
    <>
      <Container>
        <div className="container">
          <div className="topo">
            <div className="text-img">
              <img src={logo} alt="" />
              <p>Olá, {name}</p>
              <p className="descricao">Como deseja prosseguir?</p>
            </div>
          </div>

          <div className="conteudo">
            {nivel === "admin" ? (
              <div className="box">
                <NovaAcao />
                <Acoes setModalLogout={setModalLogout} />
              </div>
            ) : (
              <div className="box">
                <Acoes setModalLogout={setModalLogout} />
              </div>
            )}
          </div>
          <div className="footer">
            <button
              onClick={() => {
                localStorage.removeItem("persist:root");
                window.location.reload();
              }}
            >
              Sair
            </button>
          </div>
        </div>
      </Container>

      <Footer />

      <Modal component={FormAcao} active={modal_acao_store} />
      <Modal
        component={ModalGenerico}
        active={handleModalGenerico}
        rest={config}
      />

      <Modal
        component={ModalLogout}
        active={modalLogout}
        rest={confirUsuarioSemAcao}
      />
    </>
  );
}

export default Sistema;
